import './Servizi.css';
import ServiceCard from '../ServiceCard/ServiceCard';
import Service_1 from '../../assets/images/service_1.png';
import Service_2 from '../../assets/images/service_2.png';
import Service_4 from '../../assets/images/service_4.png';
import Service_5 from '../../assets/images/service_5.png';
import Service_6 from '../../assets/images/service_6.png';
import Service_7 from '../../assets/images/service_7.png';
import Service_8 from '../../assets/images/service_8.png';

function Servizi() {
    return (
        <div className="serviziWrapper">
            <ServiceCard icon={Service_1} name='Taglio & Shampoo' time='30' price='15.00'/>
            <ServiceCard icon={Service_2} name='Taglio & Shampoo & Barba' time='30' price='20.00'/>
            <ServiceCard icon={Service_1} name='Taglio Bimbo' time='30' price='10.00'/>
            <ServiceCard icon={Service_4} name='Shampoo & Acconciatura' time='15' price='8.00'/>
            <ServiceCard icon={Service_5} name='Barba' time='15' price='5.00'/>
            <ServiceCard icon={Service_6} name='Barba & Colore' time='15' price='10.00'/>
            <ServiceCard icon={Service_7} name='Trattamento Barba' time='15' price='10.00'/>
            <ServiceCard icon={Service_1} name='Trattamento Lisciante' time='30' price='20.00'/>
            <ServiceCard icon={Service_8} name='Barba con Panno Caldo' time='30' price='10.00'/>
            <ServiceCard icon={Service_1} name='Trattamento Lisciante & Keratina' time='30' price='45.00'/>
        </div>
    );
}

export default Servizi;