import "./HeaderMobile.css";
import HambugerIcon from "../../assets/images/hamburger.svg";
import CloseIcon from "../../assets/images/close.svg";
import { useState } from "react";
import { useTabContext } from "../../Contexts/TabContext";

function HeaderMobile() {
  const [isOpen, setIsOpen] = useState(false);
  const TabContext = useTabContext();

  const handleToggle = () => {
    setIsOpen((isOpen) => !isOpen);
  };

  const handleChangeTabMobile = (e) => {
    e.preventDefault();

    setIsOpen((isOpen) => !isOpen);
    TabContext.handleChangeTab(e);
  };

  const headerStyle = {
    position: "absolute",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    padding: "2em 1.5em",
    zIindex: "1000",
  };

  const listStyle = {
    flexDirection: "column",
    position: "absolute",
    inset: "0",
    backgroundColor: "#272727",
  };

  const selectedState = {
    color: "#000",
    backgroundColor: "#fff",
    opacity: ".5",
  };

  return (
    <>
      <header style={headerStyle}>
        <nav style={{ display: isOpen ? "block" : "none" }}>
          <ul style={listStyle} className="primaryNavigation">
            <li>
              <a
                onClick={handleChangeTabMobile}
                className="link tabLink"
                href="#"
                data-tab="Home"
                tabIndex="0"
              >
                Home
              </a>
            </li>
            <li>
              <a
                onClick={handleChangeTabMobile}
                className="link tabLink"
                href="#"
                data-tab="Storia"
              >
                Storia
              </a>
            </li>
            <li>
              <a
                onClick={handleChangeTabMobile}
                className="link tabLink"
                href="#"
                data-tab="Servizi"
              >
                Servizi
              </a>
            </li>
            <li>
              <a
                onClick={handleChangeTabMobile}
                className="link tabLink"
                href="#"
                data-tab="Prenotazioni"
              >
                Prenotazioni
              </a>
            </li>
          </ul>
        </nav>
        <button
          style={{ display: isOpen ? "none" : "block" }}
          onClick={handleToggle}
          className="menuIcon"
        >
          <img src={HambugerIcon} alt="" />
        </button>
        <button
          style={{ display: isOpen ? "block" : "none" }}
          onClick={handleToggle}
          className="menuIcon"
        >
          <img src={CloseIcon} alt="" />
        </button>
      </header>
    </>
  );
}

export default HeaderMobile;
