import { useTabContext } from "../../Contexts/TabContext";
import "./Header.css";

function Header() {
  const TabContext = useTabContext();
  const selectedState = {
    color: "#000",
    backgroundColor: "#fff",
    opacity: ".5",
  };

  return (
    <>
      <header>
        <nav>
          <ul className="primaryNavigation">
            <li>
              <a
                style={TabContext.openedTab === "Home" ? selectedState : {}}
                onClick={TabContext.handleChangeTab}
                className="link tabLink"
                href="#"
                data-tab="Home"
                tabIndex="0"
              >
                Home
              </a>
            </li>
            <li>
              <a
                style={TabContext.openedTab === "Storia" ? selectedState : {}}
                onClick={TabContext.handleChangeTab}
                className="link tabLink"
                href="#"
                data-tab="Storia"
              >
                Storia
              </a>
            </li>
            <li>
              <a
                style={TabContext.openedTab === "Servizi" ? selectedState : {}}
                onClick={TabContext.handleChangeTab}
                className="link tabLink"
                href="#"
                data-tab="Servizi"
              >
                Servizi
              </a>
            </li>
            <li>
              <a
                style={
                  TabContext.openedTab === "Prenotazioni" ? selectedState : {}
                }
                onClick={TabContext.handleChangeTab}
                className="link tabLink"
                href="#"
                data-tab="Prenotazioni"
              >
                Prenotazioni
              </a>
            </li>
          </ul>
        </nav>
      </header>
    </>
  );
}

export default Header;
