import "./Prenotazioni.css";
import PlayStore from "../../assets/images/google.png";
import AppStore from "../../assets/images/apple.png";

function Prenotazioni() {
  return (
    <div className="prenotazioniWrapper">
      <div className="prenotazioniUpper">
        <div className="timeTableWrapper">
          <h1>Orario lavorativo del Salone</h1>
          <ul className="timeTableList">
            <ul>
              <b>Lunedi: </b>
              <time>Chiuso</time>
            </ul>
            <ul>
              <b>Martedi: </b>
              <time>8:30 - 13:00 & 15:00 - 20:30</time>
            </ul>
            <ul>
              <b>Mercoledi: </b>
              <time>8:30 - 13:00 & 15:00 - 20:30</time>
            </ul>
            <ul>
              <b>Giovedi: </b>
              <time>8:30 - 13:00 & 15:00 - 20:30</time>
            </ul>
            <ul>
              <b>Venerdi: </b>
              <time>8:30 - 20:30</time>
            </ul>
            <ul>
              <b>Sabato: </b>
              <time>8:30 - 20:30</time>
            </ul>
            <ul>
              <b>Domenica: </b>
              <time>Chiuso</time>
            </ul>
          </ul>
        </div>
        <div className="reserve">
          <h1>Portale Web per le Prenotazioni</h1>
          <a className="link ctaLink" href="#">
            Riserva il tuo posto
          </a>
          <h1 className="additionalMargin">Scarica la nostra applicazione</h1>
          <div className="appLinks">
            <div>
              <a className="link storeLink" href="#">
                <img src={PlayStore} alt="" />
              </a>
            </div>
            <div>
              <a className="link storeLink" href="#">
                <img src={AppStore} alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Prenotazioni;
