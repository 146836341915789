import "./ServiceCard.css";

function ServiceCard(props) {
  return (
    <div className="ServiceCard">
      <div className="upperCard">
        <div className="serviceIcon">
          <img src={props.icon} alt="" />
        </div>
        <div className="serviceName">{props.name}</div>
      </div>
      <div className="lowerCard">
        <div className="serviceTime">Tempo: {props.time} min.</div>
        <div className="servicePrice">Prezzo: €{props.price}</div>
      </div>
    </div>
  );
}

export default ServiceCard;
