import { useContext } from "react";
import { useState, createContext } from "react";

const TabContext = createContext();

export function useTabContext() {
  return useContext(TabContext);
}

export function TabContextProvider({ children }) {
  const [openedTab, setOpenedTab] = useState("Home");

  const handleChangeTab = (e) => {
    e.preventDefault();

    const toOpen = e.target.dataset.tab;
    setOpenedTab(toOpen);
  };

  return (
    <TabContext.Provider value={{ openedTab, handleChangeTab }}>
      {children}
    </TabContext.Provider>
  );
}
