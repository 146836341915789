import "./Storia.css";
import Storia_1 from "../../assets/images/storia_1.png";
import Storia_2 from "../../assets/images/storia_2.png";
import Storia_3 from "../../assets/images/storia_3.png";

function Storia() {
  return (
    <>
      <div className="storiaWrapper">
        <div className="light">
          <div className="storiaDescription">
            All'età di 14 anni Domenico De Biase inizia a muovere i primi passi
            nel mondo della Barberia napoletana. Da subito si distingue per la
            sua professionalità nonché per la contemporaneità del suo stile
            sempre al passo con i tempi. Infatti, il salone di grande tendenza
            continua ad oggi, dopo sedici anni, ad essere al campionato
            italiano.
          </div>
          <div className="storiaImage">
            <img src={Storia_1} alt="" />
          </div>
        </div>
        <div className="dark">
          <div className="storiaImage">
            <img src={Storia_2} alt="" />
          </div>
          <div className="storiaDescription">
            Il percorso di formazione tenutosi all'Accademia di Tony e Guy, a
            Londra, contagerà l'idea di barberia di Domenico che deciderà di
            ampliare il proprio salone conferendo alla location uno stile
            urbano, un ambiente factory curato nei minimi dettagli, con
            l'attenzione rivolta al comfort e al benessere del cliente.
          </div>
        </div>
        <div className="light">
          <div className="storiaDescription">
            Ad oggi, De Biase Uomo rappresenta nel settore uno dei punti di
            riferimento del nostro territorio, una piccola ma evoluta realtà di
            imprenditoria giovanile, il cui unico obiettivo è offrire esperienza
            e competenza a tutti coloro che decidono di affidarsi ai nostri
            servizi e alla nostra professionalità.
          </div>
          <div className="storiaImage">
            <img src={Storia_3} alt="" />
          </div>
        </div>
      </div>
    </>
  );
}

export default Storia;
