import "./Home.css";

function Home() {
  return (
    <div className="Home">
      <div className="overlay"></div>
      <div className="content">
        <div className="description">
          <blockquote>
            <span>Ogni uomo ha bisogno di sentirsi speciale.</span>
            <span>Il nostro obiettivo?​</span>
            <span>Rendere unici i tuoi momenti.</span>
          </blockquote>
        </div>
      </div>
    </div>
  );
}

export default Home;
