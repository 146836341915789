import "./App.css";
import Header from "./components/Header/Header";
import HeaderMobile from "./components/HeaderMobile/HeaderMobile";
import TabHandler from "./components/TabHandler/TabHandler";
import { TabContextProvider } from "./Contexts/TabContext";
import { useState, useEffect } from "react";

function App() {
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < 650 ? true : false
  );

  useEffect(() => {
    const handleResize = () =>
      setIsMobile(window.innerWidth < 650 ? true : false);

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  });

  return (
    <div className="App">
      <TabContextProvider>
        {isMobile ? <HeaderMobile /> : <Header />}
        <TabHandler />
      </TabContextProvider>
    </div>
  );
}

export default App;
