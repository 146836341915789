import { useTabContext } from "../../Contexts/TabContext";
import Home from "../Home/Home";
import Storia from "../Storia/Storia";
import Servizi from "../Servizi/Servizi";
import Prenotazioni from '../Prenotazioni/Prenotazioni';

function TabHandler() {
  const TabContext = useTabContext();

  const toRenderTab = () => {
    switch (TabContext.openedTab) {
      case "Home":
        return <Home />;
      case "Storia":
        return <Storia />;
      case "Servizi":
        return <Servizi />;
      case "Prenotazioni":
        return <Prenotazioni />;
      default:
        return <Home />;
    }
  };

  return <>{toRenderTab()}</>;
}

export default TabHandler;
